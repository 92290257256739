import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const MotionSection = ({ children, animationVariants }) => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Allow repeated triggers
    threshold: 0.2, // Trigger when 20% of the section is visible
  });
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"} // Toggle animation state
      variants={animationVariants}
    >
      {children}
    </motion.div>
  );
};

export default MotionSection;
