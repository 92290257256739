import React from "react";
import Section from "components/section/Section";
import MotionSection from "components/section/MotionSection";
import { sections } from "components/section/sections";

const FramerEffect = () => {
  return (
    <div className="mx-4 xl:mx-24 mt-10 flex flex-col gap-10">
      <MotionSection
        animationVariants={{
          hidden: { y: 50, opacity: 0 },
          visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
        }}
      >
        <Section section={sections[0]} />
      </MotionSection>

      <MotionSection
        animationVariants={{
          hidden: { y: 50, opacity: 0 },
          visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
        }}
      >
        <Section section={sections[1]} />
      </MotionSection>

      <MotionSection
        animationVariants={{
          hidden: { y: 50, opacity: 0 },
          visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
        }}
      >
        <Section section={sections[2]} />
      </MotionSection>

      <MotionSection
        animationVariants={{
          hidden: { y: 50, opacity: 0 },
          visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
        }}
      >
        <Section section={sections[3]} />
      </MotionSection>
    </div>
  );
};

export default FramerEffect;
