import React, { useState } from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import { GoArrowRight } from "react-icons/go";

const AnimatedButton = ({ title, link = "", color="#493ED3" }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Link href={link}
      onMouseEnter={() => link && setHovered(true)}
      onMouseLeave={() => link && setHovered(false)}
    >
      <div className={`relative bg-[${color}] text-white hover:bg-opacity-80 transition-all duration-500 w-[331px] h-[100px] p-[20px] pt-[24px] pb-[20px] pl-[24px] rounded-[24px] flex items-center justify-start`} style={{backgroundColor: color}}>
        <span className="font-[600] text-[18px]">{title}</span>
        <motion.div
          className="absolute right-[24px] w-[60px] h-[60px] rounded-[16px] bg-[#AED944] flex items-center justify-center"
          initial={{ x: 0 }} // Start at its initial position
          animate={{ x: hovered ? 54 : 0 }} // Move based on hovered prop
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 20,
          }}
        >
          <GoArrowRight size={26} color="black" />
        </motion.div>
      </div>
    </Link>
  );
};

export default AnimatedButton;
