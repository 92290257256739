export const sections = [
  {
    content: (
      <div className="flex flex-col">
        <span className="text-[62px] font-[900] text-left">ԱԶԳԱՅԻՆ</span>
        <div className="bg-[#AED944] rounded-[10px] flex items-center justify-center">
          <span className="text-[64px] text-left">ԴՊՐՈՑ</span>
        </div>
        <span className="text-[26px] font-semibold text-left mt-4">
          ԱԶԳԱՅԻՆ ԿՐԹՈՒԹՅԱՆ
        </span>
        <span className="text-[26px] font-semibold text-left">
          ՎԻՐՏՈՒԱԼ ՄԻՋԱՎԱՅՐ
        </span>
      </div>
    ),
    buttons: [
      {
        title: "Ազգային կրթակարգ",
        link: "/national-curriculum",
      },
      {
        title: "Առարկայացանկ",
        link: "/subjects-list",
      },
      {
        title: "Դասընթացներ",
        link: "/courses",
      },
    ],
    imageUrl: "/cover_1.svg",
  },
  {
    content: (
      <div className="flex flex-col">
        <span className="text-[62px] font-[900] text-left">ԱԶԳԱՅԻՆ</span>
        <div className="bg-[#493ED3] rounded-[10px] flex items-center justify-center">
          <span className="text-[22px] xl:text-[32px] text-[64px] leading-[87.04px] text-white">
            ԿՐԹԱԿԱՐԳ
          </span>
        </div>
        <span className="text-[26px] font-semibold text-left mt-4">
          ԱԶԳԱՅԻՆ ԴՊՐՈՑԻ
        </span>
        <span className="text-[26px] font-semibold text-left">
          ՔԱՂԱՔԱԿՐԹԱԿԱՆ ՀԱՅԵՑԱԿԱՐԳԸ
        </span>
        <span className="text-[18px] font-[600] text-left w-full xl:w-[532px] mt-4 text-[#555555]">
          Ազգային կրթակակարգում ներառված են քաղաքակրթական հիմքի վրա ազգային
          կրթական քաղաքականության, դաստիարակության, ուսուցման, ուսումնառության և
          գնահատման ընդհանուր սկզբունքները:
        </span>
      </div>
    ),
    buttons: [
      {
        title: "Բովանդակություն",
        link: "/national-curriculum",
      },
      {
        title: "Տեսադասեր",
        link: "/national-curriculum",
      },
    ],
    imageUrl: "/cover_2.svg",
  },
  {
    content: (
      <div className="flex flex-col">
        <span className="text-[40px] xl:text-[62px] font-[900] leading-[84.32px] text-left decoration-skip-ink-none">
          ԱՌԱՐԿԱՅԱՑԱՆԿ
        </span>
        <span className="text-[18px] font-[600] text-left w-full xl:w-[532px] mt-4 text-[#555555]">
          Ազգային դպրոցի քաղաքակրթական բաղադրիչի ապահովման առարկայացանկը և
          ժամաքանակը՝1-12 րդ դասարանների ուսումնական պլանի հիմքով
        </span>
        <div className="flex flex-wrap gap-3 xl:w-[532px] mt-4">
          {[
            {
              title: "Մայրենի",
              color: "#A8A1FF",
            },
            {
              title: "Հայոց լեզու",
              color: "#FFDAFF",
            },
            {
              title: "Իմ Հայրենիքը",
              color: "#D9F19F",
            },
            {
              title: "Գրականություն",
              color: "#FFDEB2",
            },
            {
              title: "Հայոց պատմություն",
              color: "#DEDEFF",
            },
            {
              title: "Աշխարհագրություն",
              color: "#F5F5F5",
            },
            {
              title: "Հասրակագիտություն",
              color: "#FFDAFF",
            },
            {
              title: "Ես և շրջակա միջավայրը",
              color: "#A8A1FF",
            },
            {
              title: "Իմ հայրենիքը և աշխարհը",
              color: "#D9F19F",
            },
            {
              title: "Համաշխարհային պատմություն",
              color: "#FFDAFF",
            },
            {
              title: "Հայսատանի աշխարհագրություն",
              color: "#A8A1FF",
            },
          ].map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: item.color,
                }}
                className="p-[12px] rounded-[12px] w-fit text-[14px] font-[500]"
              >
                {item.title}
              </div>
            );
          })}
        </div>
      </div>
    ),
    buttons: [
      {
        title: "Իմանալ ավելին",
        link: "/national-curriculum",
      },
      {
        title: "Տեսադասեր",
        link: "/national-curriculum",
      },
    ],
    imageUrl: "/cover_2.svg",
  },
  {
    content: (
      <div className="flex flex-col">
        <span className="text-[40px] xl:text-[62px] font-[900] text-left decoration-skip-ink-none">
          Դասապրոցես
        </span>
        <span className="text-[18px] font-[500] text-left mt-10 w-full text-[#555555]">
          Բովանդակության ուսումնասիրությունից մինչև թեմաների ամփոփում և
          գնահատում:
        </span>
        <span className="text-[18px] font-[500] text-left w-full text-[#555555]">
          Հետևեք ուսումնական գործընթացին քայլ առ քայլ՝ ապահովելով արդյունավետ
          ուսուցում:
        </span>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-[24px] mb-6">
          <div className="bg-[#9747FF] rounded-[10px] px-[24px] py-[48px] w-full h-full mt-4 flex flex-col justify-center">
            <span className="text-[26px] xl:text-[32px] text-left font-[600] text-white">
              Բովանդակություն
            </span>
            <div className="text-[12px] xl:text-[18px] font-[600] text-left w-full mt-4 text-white">
              <ul className="ml-6 list-disc">
                <li>Դասի նյութեր և ռեսուրսներ</li>
                <li>Հարմարեցված ուսումնառություն</li>
                <li>Տարբեր ձևաչափեր՝ տեսանյութեր, տեքստեր</li>
              </ul>
            </div>
          </div>

          <div className="bg-[#493ED3] rounded-[10px] px-[24px] py-[48px] w-full h-full mt-4 flex flex-col justify-center">
            <span className="text-[26px] xl:text-[32px] text-left font-[600] text-white">
              Տեսադաս
            </span>
            <div className="text-[12px] xl:text-[18px] font-[600] text-left w-full mt-4 text-white">
              <ul className="ml-6 list-disc">
                <li>Առցանց կամ տեսագրությամբ դաս</li>
                <li>Դիտեք դասերը կրկնակի անգամ</li>
                <li>Սովորեք ձեր տեմպով</li>
              </ul>
            </div>
          </div>

          <div className="bg-[#493ED3] rounded-[10px] px-[24px] py-[48px] w-full h-full mt-4 flex flex-col justify-center">
            <span className="text-[26px] xl:text-[32px] text-left font-[600] text-white">
              Ամփոփում
            </span>
            <div className="text-[12px] xl:text-[18px] font-[600] text-left w-full mt-4 text-white">
              <ul className="ml-6 list-disc">
                <li>Հակիրճ ամփոփում՝ թեմաների մասին</li>
                <li>Վերանայեք դասի բովանդակությունը</li>
                <li>Կենտրոնացում՝ հիմնական կետերի վրա</li>
              </ul>
            </div>
          </div>

          <div className="bg-[#9747FF] rounded-[10px] px-[24px] py-[48px] w-full h-full mt-4 flex flex-col justify-center">
            <span className="text-[26px] xl:text-[32px] text-left font-[600] text-white">
              Հարցում
            </span>
            <div className="text-[12px] xl:text-[18px] font-[600] text-left w-full mt-4 text-white">
              <ul className="ml-6 list-disc">
                <li>Գիտելիքների հավաքում հարցաշարերով</li>
                <li>Դասի յուրացման ստուգում</li>
                <li>Խորացում թեմայում</li>
                <li>Վերլուծություն առաջընթացի</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    ),
    buttonsPosition: "below",
    height: "full",
    imageUrl: "/cover_4.svg",
    buttons: [
      {
        title: "Սովորել",
        link: "/national-curriculum",
      },
    ],
  },
];
