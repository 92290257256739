import React from "react";
import AnimatedButton from "components/button/AnimatedButton";

const Section = ({ section }) => {
  return (
    <div
      style={{ backgroundImage: `url(${section.imageUrl})` }}
      className={`h-full p-[20px_100px] rounded-[24px] flex items-center bg-white xl:border bg-center bg-no-repeat ${section.height === "full" ? "h-full" : "xl:h-[570px]"}`}
    >
      <div
        className={`flex flex-col gap-6 justify-between items-center ${section.buttonsPosition === "below" ? "xl:flex-col w-full xl:items-stretch	" : "xl:flex-row xl:gap-0 w-full"}`}
      >
        {section.content}

        <div className="flex flex-col gap-4 items-end">
          {section?.buttons?.map((button, index) => (
            <AnimatedButton key={index} title={button.title} link={button.link} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section;
